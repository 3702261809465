//@use '@angular/material' as mat;
//@include mat.core();
@import 'global/reset';

@import 'variables';
@import 'grids';
@import 'fonts';
@import 'mixins';

@import '../../node_modules/ngx-toastr/toastr.css';
@import 'table';
@import 'materials';
@import 'containers';
@import 'loader';
@import 'icons';
@import 'alerts';
@import 'formly-forms';
//@import '~@carbon/charts/styles.css';

// @tailwind base;
// @tailwind components;
// @tailwind utilities;

html,
body {
  font-family: $font-family-open-sans-regular;
  font-weight: $regular-weight;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

h1 {
  font-size: $font-size-xl;
  font-family: $font-family-open-sans-semi-bold;
  color: var(--color-theme-primary-dark);
}

h2 {
  font-size: $font-size-large;
  font-family: $font-family-open-sans-semi-bold;
  color: black;
}

h3 {
  font-size: $font-size-medium;
  font-family: $font-family-open-sans-semi-bold;
  color: var(--color-theme-primary-dark);
}

h4 {
  font-size: $font-size-small;
  color: $color-light-black;
  font-family: $font-family-open-sans-semi-bold;
}

p {
  font-size: $font-size-small;
  font-family: $font-family-open-sans-regular;
  font-weight: 400;
}

b {
  font-size: $font-size-small;
  font-family: $font-family-open-sans-semi-bold;
  font-weight: 600;
}

input, select {
  font-family: $font-family-open-sans-regular;
}

#preview-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 1.9rem;

  .grow {
    flex-grow: 1;
  }

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }
}

.errorToast {
  position: relative;
  background: rgb(247, 215, 218);
  border-radius: 3px;
  border: 1px solid rgb(233, 134, 143);
  padding: 10px 10px 10px 40px;
  width: calc(100% - 50px);
  margin: 20px 0;
  max-width: 100%;
  overflow-wrap: break-word;

  i {
    position: absolute;
    left: 10px;
    color: red;
  }
}

.successToast {
  position: relative;
  background: rgb(212, 237, 218);
  border-radius: 3px;
  border: 1px solid rgb(126, 202, 143);
  padding: 10px 10px 10px 40px;
  width: 100%;
  margin: 20px 0;

  i {
    position: absolute;
    left: 10px;
    color: green;
  }
}

.box {
  border-radius: 4px;
  border: 1px solid #cacac0;
}

.page-section {
  width: 85%;
}

.tabbed-page-top-section {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: baseline;

  .link-container {
    font-family: $font-family-open-sans-light;
    font-weight: 700;
    color: black;
  }
}

.main-title {
  font-size: $font-size-large;
  line-height: $font-size-large;
  font-family: $font-family-open-sans-semi-bold;
  letter-spacing: 0.25px;
  color: var(--color-theme-primary-dark);
}

.dialog-title {
  font-size: $font-size-large;
  font-family: $font-family-open-sans-semi-bold;
  letter-spacing: 0.25px;
  color: black;
}

.sub-title {
  padding-bottom: 1rem;
  font-size: $font-size-medium;
  font-family: $font-family-open-sans-semi-bold;
  letter-spacing: 0.25px;
  color: var(--color-theme-primary-dark);
}

.cancel-button,
.submit-button,
.save-button,
.delete-button {
  width: 6.5rem;
  margin: 0 0.2rem;
}

select {
  background: white;
  height: 35px;
  width: 21rem;
  border-radius: 3px;
}

.buttons {
  display: flex;
  width: fit-content;
  margin: 20px auto;
  justify-content: center;

  .create-button {
    margin: 5px;
  }
}
