//$color-veary-dark-blue: #002A54;
//$color-dark-blue: #003b7d;
//$color-blue: #135eb2;
//$color-light-blue: #137EE3;
$color-grey: #636363;
$color-light-grey: #a5a6a7;
$color-table-border-gray: #e1e1e0;
$color-table-even-gray: #f2f2f2;
$color-very-light-grey: #f7f5f3;
$color-selected-light-grey: #e5e9ee;
$color-black: #141415;
$color-light-black: #222222;

$font-family-open-sans-bold: 'open-sans-bold';
$font-family-open-sans-semi-bold: 'open-sans-semi-bold';
$font-family-open-sans-regular: 'open-sans-regular';
$font-family-open-sans-light: 'open-sans-light';

$font-size-xs: 0.75rem; //10px;
$font-size-small: 1rem; //14px;
$font-size-medium: 1.2rem; //~16-17px//20px;
$font-size-large: 1.93rem; //27px
$font-size-xl: 2.5rem; //35px;
$font-size-xxl: 3rem; //35px;

$light-weight: 300;
$regular-weight: 400;
$semi-bold-weight: 600;
$bold-weight: 700;
$extra-bold-weight: 800;
