@use '@angular/cdk' as cdk;
@use '@angular/material' as mat;
@import 'variables';

.mat-mdc-form-field {
  padding: unset !important;
  height: auto;
}

/* Dialog */

.mat-mdc-dialog-surface {
  padding: 2rem;
}

.mdc-button {
  margin: 1rem !important;

  & .selected {
    background-color: var(--color-theme-primary-dark);
    color: white;
    border-color: var(--color-theme-primary-dark);
  }
}

.mat-icon-button {
  width: 30px !important;
  height: 30px !important;
}

/* Select */

.mat-mdc-select-panel {
  min-width: 100% !important;
  border: 1px solid lightgrey;
  padding: 0 !important;
}

.custom.mat-mdc-select {
  display: inline-block;
  border: 1px solid lightgrey;
  line-height: 42px !important;
  height: 42px;
  border-radius: 3px;
  width: 21rem !important;
  outline: none !important;
  background: #fff;
}

.custom .mat-mdc-select-value {
  margin-left: 0.5rem;
}

.custom .mat-mdc-select-arrow {
  margin-right: 0.5rem;
}

/* Checkbox */

.mat-pseudo-checkbox.mat-pseudo-checkbox-checked {
  border-color: black !important;
}

// We want overlays to always appear over user content, so set a baseline
// very high z-index for the overlay container, which is where we create the new
// stacking context for all overlays.
$overlay-container-z-index: 1000;
$overlay-z-index: 1000;
$overlay-backdrop-z-index: 1000;

// Background color for all of the backdrops
$overlay-backdrop-color: rgba(0, 0, 0, 0.75);

// Default backdrop animation is based on the Material Design swift-ease-out.
$backdrop-animation-duration: 400ms !default;
$backdrop-animation-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1) !default;

.mat-mdc-form-field-subscript-wrapper {
  display: none;
}

.mat-mdc-input-element {
  height: 100% !important;
}

.mat-mdc-select-arrow {
  color: black !important;
}

.mat-mdc-select-value-text {
  font-size: 14px;
}

.mat-mdc-text-field-wrapper.mdc-text-field {
  border-radius: 3px;
}

button.mat-mdc-icon-button.mat-mdc-button-base {
  width: 40px;
  height: 40px;
  padding: 8px;
}

.mat-datepicker-toggle-active {
  color: black !important;
}

.mat-datepicker-input {
  height: auto !important;
}

.mat-calendar {
  height: auto !important;
}

.mat-calendar-body-selected {
  color: white !important;
}

.mat-calendar-body-cell:hover > .mat-calendar-body-cell-content {
  color: white !important;
}

.mat-step-icon-content {
  color: white;
}

mat-option.mat-mdc-option .mdc-list-item__primary-text {
  font-size: 14px;
}

mat-form-field {
  border: 1px solid lightgrey;
  border-radius: 3px;

  .mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: white;
  }

  .mdc-line-ripple {
    &::after,
    &::before {
      display: none;
    }
  }

  .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) {
    .mat-mdc-form-field-infix {
      min-height: 2.5rem;
      line-height: 2.5rem;
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}
