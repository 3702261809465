@font-face {
  font-family: 'fontello';
  src: url('../assets/fonts/icons/fontello.eot?50296275');
  src: url('../assets/fonts/icons/fontello.eot?50296275#iefix') format('embedded-opentype'),
    url('../assets/fonts/icons/fontello.woff2?50296275') format('woff2'),
    url('../assets/fonts/icons/fontello.woff?50296275') format('woff'),
    url('../assets/fonts/icons/fontello.ttf?50296275') format('truetype'),
    url('../assets/fonts/icons/fontello.svg?50296275#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}

/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
  @media screen and (-webkit-min-device-pixel-ratio:0) {
    @font-face {
      font-family: 'fontello';
      src: url('../font/fontello.svg?50296275#fontello') format('svg');
    }
  }
  */

[class^='icon-']:before,
[class*=' icon-']:before {
  font-family: 'fontello';
  font-style: normal;
  font-weight: normal;
  speak: none;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: 0.2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: 0.2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-ok:before {
  content: '\e800';
} /* '' */
x .icon-checked {
  width: 35px;
  height: 35px;
  position: relative;
  top: 20px;
  left: 10px;
  text-align: center;
  padding: 12px;
  margin-bottom: 6px;
  &:before {
    content: '';
    position: absolute;
    top: 10px;
    left: 0;
    height: 5px;
    width: 5px;
    background: white;
    transform: rotate(-45deg);
  }
  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    height: 20px;
    width: 5px;
    background: white;
    transform: rotate(45deg);
  }
}
