@import 'variables';

//common page container
.page-container {
  width: 87%;
  margin: 1.9rem auto 1.9rem;
  align-items: center;
  &.no-margin {
    margin: 0;
    width: 100%;
  }
}

//Container for placing data inside.
.data-container {
  margin-top: 1rem;
  width: 100%;

  .table-container {
    overflow: auto;
    width: 100%;
  }
}

//Simple data container is used to display simple data with key - pair values
//where they key is placed left of the box and value to the right
//<div class="simple-date-container custom-class-here">
//  <div class="your-key-class-here">key</div>
//  <div class="your-value-class-here">value</div>
//</div>
.simple-data-container {
  display: block;
  margin-top: 2rem;
  .space {
    display: flex;
    justify-content: space-between;
  }
  .data {
    height: 1.2rem;
    margin-bottom: 20px;

    div:first-child {
      float: left;
      font-size: $font-size-small;
      font-family: $font-family-open-sans-regular;
      color: black;
    }

    div:last-child {
      float: right;
      font-size: $font-size-small;
      font-family: $font-family-open-sans-semi-bold;
      color: black;
    }

    &.bold {
      .key {
        font-family: $font-family-open-sans-semi-bold !important;
      }
    }
  }
}

.creation-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .total-amount-aco {
    background: $color-very-light-grey;
    padding: 1rem 2rem;
    border: 1px solid $color-table-border-gray;
    p {
      font-size: 1.2rem;
      .total-amount-aco-bold {
        font-weight: bold;
      }
    }
  }

  > button {
    margin-top: 0!important;
  }
}

//Link container
// <div class="link-container">
//   <span class="clickable-link" (click)="navigateBack()">nav back link</span>
//   <span class="chevron> > </span>
//   <span class="current-link">current page</span>
// </div>
.link-container {
  margin-bottom: 1rem;

  .clickable-link {
    text-decoration: underline;

    &:hover {
      cursor: pointer;
      color: var(--color-theme-primary-dark);
    }
  }

  .current-link {
    color: var(--color-theme-primary-dark);
  }

  .chevron {
    margin-right: 0.3rem;
    margin-left: 0.3rem;
  }
}

.preview-container {
  width: calc(80% + 20px);
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.9rem;

  .grow {
    flex-grow: 1;
  }

  .preview-box {
    //   height: 10rem;
    flex-grow: 1;
    margin: 5px;
    display: flex;

    img {
      width: 50px;
      height: 50px;
      margin: 50px 30px 50px 30px;
    }

    .preview-details {
      padding: 0 10px 0 0;
      width: calc(100% - 100px);
    }

    .preview-counter {
      padding: 1rem 0;
      font-size: $font-size-xxl;
      font-family: $font-family-open-sans-semi-bold;
    }

    .preview-text {
      color: black;
      font-size: $font-size-small;
      font-family: $font-family-open-sans-semi-bold;
    }
  }

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }
}

.dashboard-accounts-containers {
  min-height: 22rem;
  flex-grow: 1;
  padding-right: 0.8rem;
}

.global-account-graphs-container {
  height: 22rem;
  padding-right: 0.8rem;
}

.break-word {
  word-break: break-all;
}

.max-70 {
  max-width: 70%;
}

.max-60 {
  max-width: 60%;
}
