$input-height: 40px;
$input-border-color: lightgrey;

formly-group {
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  formly-field {
    width: 100%;
  }
}

.formly-field {
  input.form-control, select.form-select {
    outline: none;
    border: 1px solid $input-border-color;
    border-radius: 3px;
    padding: 0;
    font-size: 1rem;

    &:focus {
      outline: none;
    }

    &[disabled] {
      background: #fafafa;
      color: #9b9b9b;
    }
  }

  input.form-control {
    padding-left: 1rem;
    width: calc(100% - 1rem - 2px);
    height: calc(#{$input-height} - 2px);
  }

  select.form-select {
    display: inline-block;
    width: calc(100% + 2px);
    height: calc(#{$input-height} + 2px);
    padding-left: 12px;
  }

  .form-check {
    display: inline-block;
    line-height: 40px;
    margin-right: 15px;
  }
}
