@import './variables';
.table {
  width: 100%;
  margin: 1rem auto 2rem;
  display: table;
  border-bottom: 1px solid $color-table-border-gray;

  tr {
    th {
      font-family: $font-family-open-sans-semi-bold;
      position: relative;
      background: var(--color-theme-primary-very-dark);
      color: white;

      text-align: left;
      padding: 10px;
      border-right: 1px solid $color-table-border-gray;

      &:last-child {
        border-right: 0 solid var(--color-theme-primary-very-dark);
      }

      &:first-child {
        border-left: 0 solid var(--color-theme-primary-very-dark);
      }

      &.number {
        text-align: right;
      }
    }

    td {
      min-width: 120px;
      color: black;
      text-align: left;
      padding: 10px;
      border-right: 1px solid $color-table-border-gray;

      &.number {
        text-align: right;
      }

      &:first-child {
        border-left: 1px solid $color-table-border-gray;
      }

      &.link {
        color: #135eb2;
        cursor: pointer;

        &:hover {
          color: #003b7d;
        }
      }

      &.blocked-status {
        text-align: center;
        color: #0550a3;
        padding-right: 30px;
        min-width: 100px;
        cursor: default;

        //&.is-blocked {
        //  color: #a94442;
        //}

        span.updatable {
          cursor: pointer;
        }

        span:not(.updatable) {
          color: #9d9d9d!important;
        }
      }

      .valid-date {
        display: flex;
        flex-direction: row;
        align-items: center;
        & p {
          margin-right: 0.5rem;
        }

        mat-icon {
          color: $color-light-black;
          cursor: pointer;
        }
      }
    }

    &:nth-child(even) {
      background: white;
    }

    &:nth-child(odd) {
      background: $color-very-light-grey;
    }

    &:hover {
      background: $color-selected-light-grey;
    }
  }

  &.sortable {
    th,
    td {
      padding-left: 30px;
    }
  }
}

.table-borderless {
  border-bottom: 0 solid $color-table-border-gray;

  tr {
    th {
      font-family: $font-family-open-sans-light;
      border-right: 0 solid $color-table-border-gray;
      padding: 5px 10px;

      &:last-child {
        border-right: 0 solid var(--color-theme-primary-very-dark);
      }

      &:first-child {
        border-left: 0 solid var(--color-theme-primary-very-dark);
      }

      &.number {
        text-align: right;
      }
    }

    td {
      border: 0 solid $color-table-border-gray;
      padding: 5px 10px;

      &:first-child {
        border-left: 0 solid $color-table-border-gray;
      }
    }
  }
}

.table-white {
  background: white;
  border: 0 solid white;

  tr {
    background: white;
    border: 0 solid white;

    th {
      background: white;
      color: black;
      font-family: $font-family-open-sans-light;
      font-size: $font-size-xs;
      border: 0 solid white;

      &:last-child {
        border-right: 0 solid white;
      }

      &:first-child {
        border-left: 0 solid white;
      }
    }

    td {
      color: black;
      font-family: $font-family-open-sans-semi-bold;
      border: 0 solid white;

      font-size: $font-size-xs;

      &.number {
        text-align: right;
      }

      &:first-child {
        border-left: 0 solid white;
      }
    }

    &:nth-child(even) {
      background: white;
    }

    &:nth-child(odd) {
      background: white;
    }
  }

  &.sortable {
    th,
    td {
      padding-left: 30px;
    }
  }
}

.triangle-up {
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid white;
  margin: auto;
}

.triangle-down {
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 7px solid white;
  margin: auto;
}

.sort {
  position: absolute;
  top: 11px;
  left: 10px;
  display: flex;
  flex-direction: column;
  height: 18px;
}

.filter {
  display: flex;
  justify-content: flex-end;
  margin: 0 10px 0 20px;
  visibility: visible;

  input {
    padding: 5px 10px;
    width: 18rem;
    border: 1px solid $color-light-grey;
    font-family: $font-family-open-sans-light;
  }

  i {
    font-size: 32px;
    margin-left: 10px;
    color: black;
  }

  &.hidden {
    visibility: hidden;
  }
}

.filter.small {
  display: flex;
  justify-content: flex-end;
  margin: 5px 10px 0 20px;

  input {
    padding: 5px 10px;
    width: 15rem;
    border: 1px solid $color-light-grey;
    font-family: $font-family-open-sans-light;
  }

  i {
    font-size: 24px;
    margin-left: 10px;
    color: black;
  }
}

.error-message {
  color: red;
  font-size: 1rem;
  margin: auto;
  width: fit-content;
}

.no-data {
  text-align: center;
  font-size: 16px;
}

.error {
  font-size: 16px;
  padding: 1rem;
  min-width: 200px;
}

.table-overflow {
  display: block;
  overflow-x: auto;
}

.no-overflow-modal {
  .mat-mdc-dialog-container .mdc-dialog__surface {
    overflow-y: hidden;
    max-height: 85vh;
  }
}
