
.alert {
  position: relative;
  padding: 10px 10px 10px 40px;
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 3px;
  overflow-wrap: break-word;

  &::before {
    position: absolute;
    left: 12px;
  }

  &.alert-warning {
    background-color: #fff3cd;
    border-color: #ffeeba;

    &::before {
      content: "⚠";
      color: #856404;
    }
  }

  &.alert-error {
    background: rgb(247, 215, 218);
    border: 1px solid rgb(233, 134, 143);

    &::before {
      content: "✕";
      color: red;
    }
  }
}
